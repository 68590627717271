import React, { Component } from 'react';
import "./info.css";
import icone_descr from "../images/icone_nere.svg";
import { Parallax } from "react-parallax";
import {Link/*, withRouter*/} from "react-router-dom";
import CardEvento from "./CardEvento";
import $ from "jquery";

import img_tavoli_par from "../images/nuova-2-comp.jpg";
import img_bistrot_par from "../images/nuova-3-comp.jpg";
import img_albergo_par from "../images/nuova-1-comp.jpg";
import img_ristorante from "../images/1-ristorante-comp.jpeg";
import img_bistrot from "../images/2-aperitivo-comp.jpg";
import img_camera from "../images/4-comp-camera.jpg";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..


export default class Info extends Component {


  componentDidMount(){
    
    //setTimeout(function(){ AOS.init();}, 1000); 
    $(document).ready ( function(){
      AOS.init();
   })
   $(window).on('load', function() {
    AOS.refresh();
    });
    AOS.refresh();
}

  render() {

    return (
      <div className="contenitore_info" >
        <div id="info" data-aos="zoom-in-up" data-aos-duration="1000" >
          CASANATURA E' UN'ESPERIENZA A TUTTO TONDO: <br />
          DAL RISTORANTE AL BISTROT, DALL'ALBERGO ALLE ESCURSIONI
        </div>
        <div id="icone_descr" data-aos="zoom-in-up" data-aos-duration="1000" >
          <img src={icone_descr} alt="icone_img" id="icone_img"/>
        </div>

        <div>
          <CardEvento />
        </div>

      {/*RISTORANTE*/}
      <Parallax className="par_container" bgImage={img_tavoli_par} strength={500}>
        <div className="par_h">
          <div className="inside_par"  id="ristorante">Ristorante</div>
        </div>
      </Parallax>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <img src={img_ristorante} alt="ristorante" className="img_left" data-aos="zoom-in" data-aos-duration="1000"
            />
          </div>
          <div className="col-lg-6 descr">
            <div className="col-lg-6 descr_item descr_title" data-aos="fade-left" data-aos-duration="1000"
            >
              qualità e tradizione
            </div>
            <hr className="descr_divisore" data-aos="fade-left" data-aos-duration="1000"
            />
            <div className="col-lg-6 descr_item descr_descr" data-aos="fade-left" data-aos-duration="1000"
            >
            Solo materie prime del territorio per soddisfare tutti i palati, 
            attraverso una cucina genuina come quella di casa tua
            </div>
            <div className="col-lg-6 descr_item">
              <Link to="/menu">
              <button 
              className="btn btn-outline-danger btn-lg" 
              data-aos="fade-left" 
              data-aos-duration="1000"
              >
              <div className="descr_in_btn">VAI AL MENU</div>
              </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/*BISTROT*/}

      <Parallax className="par_container" bgImage={img_bistrot_par} strength={500}>
        <div id="bistrot" className="par_h">
          <div className="inside_par" id="bistrot">Bistrot</div>
        </div>
      </Parallax>

      <div className="container-fluid">
        <div className="row">
          
          <div className="col-lg-6 descr">
            <div className="col-lg-6 descr_item descr_title" data-aos="fade-right" data-aos-duration="1000"
            >
              aperitivo sofisticato
            </div>
            <hr className="descr_divisore" data-aos="fade-right" data-aos-duration="1000"
            />
            <div className="col-lg-6 descr_item descr_descr" data-aos="fade-right" data-aos-duration="1000"
            >
            Goditi un drink rinfrescante sulla terrazza con vista e 
            gusta le nostre sfiziosità immerso nella quiete del parco
            </div>
            <div className="col-lg-6 descr_item">
            <Link to="/drink">
            <button className="btn btn-outline-danger btn-lg" 
            data-aos="fade-right" 
            data-aos-duration="1000"
            >DRINK LIST</button>
            </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <img src={img_bistrot} alt="bistrot" className="img_right" data-aos="zoom-in" data-aos-duration="1000"
            />
          </div>
        </div>
      </div>

      {/*ALBERGO*/}

      <Parallax className="par_container" bgImage={img_albergo_par} strength={500}>
          <div id="albergo" className="par_h">
            <div className="inside_par" id="albergo">Albergo</div>
          </div>
        </Parallax>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <img src={img_camera} alt="camera" className="img_left" data-aos="zoom-in" data-aos-duration="1000"
            />
          </div>
          <div className="col-lg-6 descr">
            <div className="col-lg-6 descr_item descr_title" data-aos="fade-left" data-aos-duration="1000"
            >
              comfort totale
            </div>
            <hr className="descr_divisore" data-aos="fade-left" data-aos-duration="1000" />
            <div className="col-lg-6 descr_item descr_descr" data-aos="fade-left" data-aos-duration="1000"
            >
            Punto di partenza ideale per visitare il Cilento, 
            il nostro albergo offre soluzioni flessibili per la tua pausa rigenerante
            </div>
            <div className="col-lg-6 descr_item">
              <Link to="/albergo_spec">
                <button className="btn btn-outline-danger btn-lg" 
                data-aos="fade-left" 
                data-aos-duration="1000" >SCOPRI LE CAMERE</button>
              </Link>  
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }
}




