import React, { Component } from 'react';
import jwt_decode from "jwt-decode";
import "./Gest_menu.css";
import Delete from "../images/close.svg";
import Modifica from "../images/edit.svg";
import topBottom from "../images/topBottom.svg";
import jsPDF from "jspdf";
import pdfIcon from "../images/pdf.svg";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';


export default class Gest_menu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      listaPiatti: [],
      listaPiattiAttivi: [],
      tipoPiatti: [],
      idTipoPiatto: "1",
      nome: "",
      prezzo: "",
      descrizione: "",
      posizione:"",
      nome_mod:"",
      prezzo_mod:"",
      descrizione_mod: "",
      idTipoPiatto_mod: "",
      id_mod: "",
      blocking:false
    }
    

    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.eliminaElemento = this.eliminaElemento.bind(this);
    this.gestisciModifica = this.gestisciModifica.bind(this);
    this.submitModifica = this.submitModifica.bind(this);
    this.moveElem = this.moveElem.bind(this);
    this.exportMenu = this.exportMenu.bind(this);
    this.blockSchermo = this.blockSchermo.bind(this);
    this.sbloccoSchermo = this.sbloccoSchermo.bind(this);
    this.logOut = this.logOut.bind(this);

  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    this.setState({
      username: decoded.username
    });
    this.categoriaPiatto();
    this.getPiatti();
    this.getPiattiAttivi();
  }
  blockSchermo() {
    this.setState({
      blocking: true
    });
  }

  sbloccoSchermo() {
    this.setState({
      blocking: false
    });
  }

  logOut(e){
    //e.preventDefault();
    localStorage.removeItem("usertoken");
    this.props.history.push("/");
  }

  getPiatti() {
    this.blockSchermo(); 
    fetch("/get_piatti", {
      method: 'POST',
    })
      .then(res => res.json())
      .then( result => {
        let listaPiatti = result.data.map((item) => {
          const {id, nome, descrizione, prezzo, attivo, idTipologia, posizione} = item;
          return {id, nome, descrizione, prezzo, attivo, idTipologia, posizione}
        });
        this.setState({
          listaPiatti
        }, () =>  this.sbloccoSchermo());
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  getPiattiAttivi() {
    fetch("/get_piatti_attivi", {
      method: 'POST',
    })
      .then(res => res.json())
      .then( result => {
        let listaPiattiAttivi = result.data.map((item) => {
          const {id, nome, descrizione, prezzo, attivo, idTipologia} = item;
          return {id, nome, descrizione, prezzo, attivo, idTipologia}
        });
        this.setState({
          listaPiattiAttivi
        });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  exportMenu(){
    var doc = new jsPDF();
  
    doc.setTextColor("#BA1852");
    doc.setFontSize(30);
    doc.setFontType("bold");
    doc.text('MENU', 90, 20);
    var posY = 20;
    var pageHeight= 240;
    

    this.state.tipoPiatti.map(tipo => {
      doc.setFontSize(18);
      doc.setTextColor("#BA1852");
      posY += 20;
      doc.setFontType("bold");
      doc.text(tipo.descrizione, 30, posY);
      doc.setTextColor("0","0","0");
      this.state.listaPiattiAttivi.filter(item => item.idTipologia === tipo.id)
        .map( filteredPiatto =>{
          doc.setFontType("normal");
          if (posY>=pageHeight)
          {
            doc.addPage();
            posY = 10;
          }

          doc.setFontSize(12);
          posY += 10;
          doc.setTextColor("#BA1852");
          doc.text(filteredPiatto.nome, 30, posY);
          doc.text(filteredPiatto.prezzo +' €', 180, posY, {align:'right'});
          doc.setTextColor("0","0","0");
          posY += 2;
          doc.setDrawColor("0","0","0");
          doc.line(30, posY, 180, posY);
          posY += 5;
          doc.text(filteredPiatto.descrizione, 30, posY, {maxWidth: 150});
          console.log(String(filteredPiatto.descrizione.length));
          if(filteredPiatto.descrizione.length > 75){
            posY += 5;  
          }else if(filteredPiatto.descrizione.length > 150){
            posY += 10;
          };
          posY += 5;
        })
    })

    doc.save('Menu.pdf');
  }

  inputChangeHandler(evt) { 
    if(evt.target.type === "checkbox"){
      this.blockSchermo();
      fetch('/disattiva_attiva_piatto', {  
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({id: evt.target.id,token:localStorage.usertoken})  
    })
    .then(res => res.json())
    .then(result => {
      if(result.error){
        alert("Sessione scaduta, rieseguire il login");
        this.logOut();
      } else {

        this.getPiatti();
      }
    })
    };
    this.setState({ 
      [evt.target.name]: evt.target.value 
    });
  }

  eliminaElemento(evt) {  
    fetch('/elimina_piatto', {  
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
        body: JSON.stringify({id: evt.target.id, token:localStorage.usertoken})  
      })
      .then(res => res.json())
      .then(result => {
        if(result.error){
          alert("Sessione scaduta, rieseguire il login");
          this.logOut();
        } else {
          this.getPiatti();
        }
    })
  }

  gestisciModifica(props){
    this.setState({
      nome_mod: props.nome,
      idTipoPiatto_mod: props.idTipologia,
      prezzo_mod: props.prezzo,
      descrizione_mod: props.descrizione,
      id_mod: props.id
    });
  }

  submitHandler(){
    var objSave = {};

    objSave = {
      tipo: this.state.idTipoPiatto,
      nome: this.state.nome,
      prezzo: this.state.prezzo,
      descrizione: this.state.descrizione
    }
    if(objSave.tipo !== "" && objSave.nome !== "" && objSave.prezzo !== "") {
        fetch('/aggiungi_piatto', {  
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({objSave, token:localStorage.usertoken})  
        })
        .then((response) => response.json())
        .then(res => {
          if(res.error){
            alert("Sessione scaduta, rieseguire il login");
            this.logOut();
          } else {
            let listaPiatti = res.data.map((item) => {
              const {id, nome, descrizione, prezzo, attivo, idTipologia, posizione} = item;
              return {id, nome, descrizione, prezzo, attivo, idTipologia, posizione}
            });
            this.setState({
              listaPiatti,
              nome: "",
              prezzo: "",
              descrizione: ""
            });
          }
        })
    .catch(error => console.log("Si è verificato un errore!"));
    }
  }


  submitModifica() {
    var objSave = {};

    objSave = {
      id: this.state.id_mod,
      tipo: this.state.idTipoPiatto_mod,
      nome: this.state.nome_mod,
      prezzo: this.state.prezzo_mod,
      descrizione: this.state.descrizione_mod
    }
    if(objSave.tipo !== "" && objSave.nome !== "" && objSave.prezzo !== "") {
        fetch('/modifica_piatto', {  
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({objSave, token:localStorage.usertoken})  
        })
        .then(res => res.json())
        .then(result => {
          if(result.error){
            alert("Sessione scaduta, rieseguire il login");
            this.logOut();
          } else {
            this.getPiatti();
          }
        })
      }
  }


  categoriaPiatto() {
    fetch("/categoria_piatto", {
      method: 'POST',
    })
      .then(res => res.json())
      .then( result => {
        let results = result;
        let tipoPiatti = results.data.map((item) => {
          const {id, descrizione} = item;
          return {id , descrizione}
        });
        this.setState({tipoPiatti});
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  moveElem(pos, elem) {
    this.blockSchermo(); 
    var tipo = elem.idTipologia;
    var id = elem.id;
    var posizione = elem.posizione;
    console.log(posizione);
    fetch('/muovi_piatto', {  
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({movimento: pos, tipo, id, posizione, token:localStorage.usertoken})  
    }).then(res => res.json())
    .then((result) => {
      if(result.error){
      alert("Sessione scaduta, rieseguire il login");
      this.logOut();
    } else {
      this.getPiatti();
    }})
    this.sbloccoSchermo();
  }
  

  render() {
    return (
      <div className="font container_gest_menu">
        <BlockUi tag="div" blocking={this.state.blocking}>
        <p id="menu_title">Aggiungi piatto</p>
        <div id="aggiungi_piatto">
          <div className="row">
            <div className="col-4">
              <label>Tipologia</label>
              <select className="form-control" onChange={this.inputChangeHandler} 
              value={this.state.tipoPiatti.value}
              name="idTipoPiatto">
                {this.state.tipoPiatti.map((item) =>{
                  return(
                  <option key={item.id}
                  value={item.id}>
                    {item.descrizione}
                  </option>
                  )
                })}
              </select>
            </div>
            <div className="col-4">
              <label>Nome</label>
              <input className="form-control" type="text" placeholder="Nome"
              name="nome"
              value={this.state.nome}
              onChange={this.inputChangeHandler}/>
            </div>
            <div className="col-4">
              <label>Prezzo</label>
              <input className="form-control" type="text" placeholder="Prezzo"
              name="prezzo"
              value={this.state.prezzo}
              onChange={this.inputChangeHandler}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <label>Descrizione</label>
              <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
              name="descrizione"
              value={this.state.descrizione}
              onChange={this.inputChangeHandler}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <button 
                className="btn btn-outline-danger btn-lg" 
                onClick={this.submitHandler} >
                <div className="descr_in_btn">Aggiungi piatto</div>
              </button>
            </div>
            <div className="col-2" style={{textAlign: "center"}}>
              <img className="pdfIcon" src={pdfIcon} onClick={this.exportMenu}/>
            </div>
          </div>
        </div>

        {/*ANTIPASTI*/}
        <div className="container_piatti">
          {/*this.state.tipoPiatti.map(tipo => {
return(
              this.state.listaPiatti.filter(item => item.idTipologia == tipo.id)
                .map( filteredPiatto =>{
                  <h3>{tipo.descrizione}</h3>
                  return(
                    <div className="row">
                      <div className="col">{filteredPiatto.nome}</div>
                      <div className="col">{filteredPiatto.prezzo}</div>
                      <div className="col">{filteredPiatto.descrizione}</div>
                      <div className="col">
                        <div className="row">
                          <div className="col"></div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                    </div>
                  )
                })
)
          })*/}

        {this.state.tipoPiatti.map(tipo => {
          return(<>
          <h3 className="tipo_piatti">{tipo.descrizione}</h3>
          <div className="titoli">
          <div className="row divisore">
            <div className="col">Nome</div>
            <div className="col">Prezzo</div>
            <div className="col">Descrizione</div>
            <div className="col azioni">Azioni</div>
          </div>
          </div>
            {this.state.listaPiatti.filter(item => item.idTipologia === tipo.id)
                .map( filteredPiatto =>{
                  return(
                    <div className="row divisore" key={filteredPiatto.id}>
                      <div className="col">{filteredPiatto.nome}</div>
                      <div className="col"><>&euro; </>{filteredPiatto.prezzo}</div>
                      <div className="col">{filteredPiatto.descrizione}</div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <img className="edit"
                              alt="edit" 
                              src={Modifica} 
                              onClick={() => this.gestisciModifica(filteredPiatto)}
                              data-toggle="modal" 
                              data-target="#modifica"/>
                          </div>
                          <div className="col">
                            <img className="delete" 
                            alt="delete"
                            src={Delete}
                            id={filteredPiatto.id}
                            onClick={this.eliminaElemento}/>
                          </div>
                          <div className="col">
                            <label className="switch">
                            <input type="checkbox" 
                            className="checkbox" 
                            key={filteredPiatto.id}
                            id={filteredPiatto.id}
                            checked={filteredPiatto.attivo === 1 ? true : false}
                            onChange={this.inputChangeHandler}/>
                            <span className="slider round"></span>
                          </label>
                          </div>
                          <div className="col arrows">
                            <div className="row"><div className="col">
                              <img className="topBottom arr_top" 
                                src={topBottom} 
                                onClick={() => this.moveElem(1, filteredPiatto)}
                              />
                            </div></div>
                            
                            <div className="row"><div className="col">
                              <img className="topBottom arr_bottom" 
                                src={topBottom} 
                                onClick={() => this.moveElem(2, filteredPiatto)}
                              />
                            </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                )
                  
                })}
          </>)
        })}
        <div className="modal fade" id="modifica" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Modifica</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">

              <div className="row">
                <div className="col-4">
                  <label>Tipologia</label>
                  <select className="form-control" onChange={this.inputChangeHandler} 
                  value={this.state.idTipoPiatto_mod}
                  name="idTipoPiatto_mod">
                    {this.state.tipoPiatti.map((item) =>{
                      return(
                      <option key={item.id}
                      value={item.id}>
                        {item.descrizione}
                      </option>
                      )
                    })}
                  </select>
                </div>
                <div className="col-4">
                  <label>Nome</label>
                  <input className="form-control" type="text" placeholder="Nome"
                  name="nome_mod"
                  value={this.state.nome_mod}
                  onChange={this.inputChangeHandler}/>
                </div>
                <div className="col-4">
                  <label>Prezzo</label>
                  <input className="form-control" type="text" placeholder="Prezzo"
                  name="prezzo_mod"
                  value={this.state.prezzo_mod}
                  onChange={this.inputChangeHandler}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12">
                  <label>Descrizione</label>
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                  name="descrizione_mod"
                  value={this.state.descrizione_mod}
                  onChange={this.inputChangeHandler}
                  />
                </div>
                </div>
            
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Annulla</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal"
                onClick={this.submitModifica}>Salva</button>
              </div>
            </div>
          </div>
        </div>
        </div>
        </BlockUi>
      </div>
    )
  }
}
