import React, { Component } from 'react';
import "./RichiediInfo.css";
import DatePicker, {registerLocale /*setDefaultLocale*/ } from 'react-datepicker';
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
//import { format } from "date-fns";
import $ from "jquery";
//import $ from "jquery";
import it from 'date-fns/locale/it';
registerLocale('it', it)

export default class RichiediInfo extends Component {

  constructor(props) {
    super(props);

    this.state = {
      subject:"Richiesta disponibilita",
      nome:"",
      cognome:"",
      mail:"",
      telefono:"",
      ospiti_bambini:0,
      ospiti_adulti:1,
      giorno_da:"",
      giorno_a:"",
      messaggio_aggiuntivo:"",
      errore_show: false,
      inviato: false
    }

    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.IncrementItem_adulto = this.IncrementItem_adulto.bind(this);
    this.disattivaNotifica = this.disattivaNotifica.bind(this);
  }

  sendMail() {
    const {nome, cognome, mail, telefono, giorno_da, giorno_a} = this.state;
    var objSave = {...this.state};
    if(nome !== "" && cognome !== "" && mail !== "" && telefono !== "" && giorno_da !== "" && giorno_a !== ""){
      objSave.giorno_da = moment(objSave.giorno_da).format("DD/MM/yyyy");
      objSave.giorno_a = moment(objSave.giorno_a).format("DD/MM/yyyy");
      fetch("/send_mail", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({objSave}) 
      }).then(result => {
        if(true){
          //GESTISCI ERRORE
          this.setState({
            nome:"",
            cognome:"",
            mail:"",
            telefono:"",
            ospiti_bambini:0,
            ospiti_adulti:1,
            giorno_da:"",
            giorno_a:"",
            messaggio_aggiuntivo:""
          });
        }else {
          //MESSAGGIONE DI ERRORE
        }
      })
      this.setState({errore_show: false, inviato: true});
      $("[data-dismiss=modal]").trigger({ type: "click" });
      setTimeout(this.disattivaNotifica, 3000);
    }else {
      this.setState({
        errore_show: true,
      });
    }

  }


  disattivaNotifica() {
    
        this.setState({inviato: false}); 
  }

  IncrementItem_adulto = (e) => {
    e.preventDefault();
    this.setState({ ospiti_adulti: this.state.ospiti_adulti + 1 });
  }
  DecreaseItem_adulto = (e) => {
    e.preventDefault();
    this.state.ospiti_adulti > 1 ? 
    this.setState({ ospiti_adulti: this.state.ospiti_adulti - 1 }) : this.setState({ ospiti_adulti: this.state.ospiti_adulti - 0 }) ;
  }

  IncrementItem_bambino = (e) => {
    e.preventDefault();
    this.setState({ ospiti_bambini: this.state.ospiti_bambini + 1 });
  }
  DecreaseItem_bambino = (e) => {
    e.preventDefault();
    this.state.ospiti_bambini > 0 ? 
    this.setState({ ospiti_bambini: this.state.ospiti_bambini - 1 }) : this.setState({ ospiti_bambini: this.state.ospiti_bambini - 0 }) ;
  }

  handleChange_da = date => {
    this.setState({
      giorno_da: date
    });
  };
  handleChange_a = date => {
    this.setState({
      giorno_a: date
    });
  };

  inputChangeHandler(evt) {
    this.setState({ 
      [evt.target.name]: evt.target.value 
    });
  }

  render() {
    return (
      <>
      <div className="modal show" id="form_richiedi_info" tabindex="-1" 
      role="dialog" aria-labelledby="form_richiedi_info" >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="form_richiedi_info">Richiedi disponibilità</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="needs-validation" novalidate>

                <div className="form-row riga_informazioni">
                  <div className="col-lg-3 sm-12 item_informazioni">
                    <label className="titolo_input_informaizoni" for="formControlInput">Nome</label>
                    <input type="text" 
                    className= {this.state.errore_show === true 
                      ? "form-control error_input" 
                      : "form-control"} 
                    placeholder="Nome" 
                    value={this.state.nome}
                    name="nome"
                    onChange={this.inputChangeHandler}
                    required
                    />
                    {(this.state.errore_show === true && this.state.nome === "") 
                    ? <div className="errore_form">*Inserire un nome</div> : ""}
                  </div>

                  <div className="col-lg-3 sm-12 item_informazioni">
                    <label className="titolo_input_informaizoni" for="formControlInput">Cognome</label>
                    <input type="text" 
                    className= {this.state.errore_show === true 
                    ? "form-control error_input" 
                    : "form-control"} 
                    placeholder="Cognome" 
                    value={this.state.cognome}
                    name="cognome"
                    onChange={this.inputChangeHandler}
                    />
                    {(this.state.errore_show === true && this.state.cognome === "") 
                    ? <div className="errore_form">*Inserire un cognome</div> : ""}
                  </div>

                  <div className="col-lg-3 sm-12 item_informazioni">
                    <label className="titolo_input_informaizoni" for="formControlInput">Email</label>
                    <input type="email" 
                     className= {this.state.errore_show === true 
                      ? "form-control error_input" 
                      : "form-control"} 
                    id="formControlInput" placeholder="nome@esempio.com" 
                    value={this.state.mail}
                    name="mail"
                    onChange={this.inputChangeHandler}/>
                    {(this.state.errore_show === true && this.state.mail === "") 
                    ? <div className="errore_form">*Inserire una email</div> : ""}
                  </div>

                  <div className="col-lg-3 sm-12 item_informazioni">
                    <label className="titolo_input_informaizoni" for="formControlInput">Tel./Cell.</label>
                    <input type="text" 
                     className= {this.state.errore_show === true 
                      ? "form-control error_input" 
                      : "form-control"} 
                    id="formControlInput" placeholder="3912344567" 
                    value={this.state.telefono}
                    name="telefono"
                    onChange={this.inputChangeHandler}/>
                    {(this.state.errore_show === true && this.state.telefono === "") 
                    ? <div className="errore_form">*Inserire un recapito telefonico</div> : ""}
                  </div>
                </div>

                <div className="form-row riga_informazioni counters">

                  <div className="col-lg-3 sm-12">
                    <label className="titolo_input_informaizoni" for="formControlInput">Adulti</label>
                    <div className="row">
                      <div className="col-3">
                        <button className="operatore" 
                        onClick={this.DecreaseItem_adulto}>-</button>
                      </div>
                      <div className="col-3">
                        <div className="tot_adulti" 
                        value={this.state.ospiti_adulti}>
                          {this.state.ospiti_adulti}
                        </div>
                      </div>
                      <div className="col-3">
                        <button className="operatore" 
                        onClick={this.IncrementItem_adulto}>+</button>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 sm-12">
                    <label className="titolo_input_informaizoni" for="formControlInput">Bambini</label>
                    <div className="row">
                      <div className="col-3">
                        <button className="operatore" 
                        onClick={this.DecreaseItem_bambino}>-</button>
                      </div>
                      <div className="col-3">
                        <div className="tot_adulti" 
                        value={this.state.ospiti_bambini}>
                          {this.state.ospiti_bambini}
                        </div>
                      </div>
                      <div className="col-3">
                        <button className="operatore" 
                        onClick={this.IncrementItem_bambino}>+</button>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 sm-12 check">
                    <label className="titolo_input_informaizoni" for="formControlInput">Check In</label>
                    <DatePicker 
                      selected={this.state.giorno_da}
                      onChange={this.handleChange_da}
                      className= {this.state.errore_show === true 
                        ? "date_picker error_input" 
                        : "date_picker"}
                      placeholder="GG/MM/AAAA"
                      dateFormat="DD/MM/yyyy"
                      locale="it"
                      value={(this.state.giorno_da) ? moment(this.state.giorno_da).format("DD/MM/yyyy") : ""}
                    />
                    {(this.state.errore_show === true && this.state.giorno_da === "") 
                    ? <div className="errore_form">*Selezionare data di arrivo</div> : ""}
                  </div>

                  <div className="col-lg-3 sm-12 check">
                    <label className="titolo_input_informaizoni" for="formControlInput">Check Out</label>
                    <DatePicker 
                      selected={this.state.giorno_a}
                      onChange={this.handleChange_a}
                      className= {this.state.errore_show === true 
                        ? "date_picker error_input" 
                        : "date_picker"}
                      placeholder="GG/MM/AAAA"
                      dateFormat="DD/MM/yyyy"
                      locale="it"
                      value={(this.state.giorno_a) ? moment(this.state.giorno_a).format("DD/MM/yyyy") : ""}
                    />
                    {(this.state.errore_show === true && this.state.nome === "") 
                    ? <div className="errore_form">*Selezionare data di partenza</div> : ""}
                  </div>
                </div>

                <div className="form-row riga_informazioni">
                  <div className="col-12">
                    <label className="titolo_input_informaizoni" for="exampleFormControlTextarea1">Informazioni aggiuntive</label>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                    value={this.state.messaggio_aggiuntivo}
                    name="messaggio_aggiuntivo"
                    onChange={this.inputChangeHandler}></textarea>
                  </div>
                </div>

              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary modal_info_control" data-dismiss="modal">CHIUDI</button>
              <button id="invia" type="button" className="btn btn-primary modal_info_control"
              onClick={this.sendMail}>INVIA</button>
            </div>
          </div>
        </div>
      </div>
      {this.state.inviato === true ? <div className="notificaInviato">Richiesta inviata correttamente</div> : ""}
      
      </>
    )
  }
}
