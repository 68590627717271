import React from 'react';
import "./Footer.css";
//import icone_social from "../images/ICONE_SOCIAL.svg";
import {Link} from "react-router-dom";
import Instagram from "../images/instagram.png";
import Facebook from "../images/facebook.png";
import Wa from "../images/wa.png";


export default function Footer() {
  return (
    <>
    <div id="footer">
      <div id="f_title">
        CONTATTI
      </div>

      <div className="center_elems">
        <div className="row">
          <div className="col-lg-4 sm-12 f_col">
          <p>DOVE SIAMO</p>
          <p>Indirizzo: <br />
          Via Sant’Antonio 47 <br />
          84050 Laurito (SA)</p>

          <a id="contatti_f" href="mailto:casanatura2020@gmail.com">casanatura2020@gmail.com</a>
          <br />

          Tel: <a id="contatti_f" href="tel:+393441310350">3441310350</a> <br />
          Fax: 0974 1874315
          </div>
          <div className="col-lg-4 sm-12 f_col">
          <p>ORARI DI APERTURA</p>
          {/*<p>Bistrot: <br />
          14:00/19:00 (lun/dom)</p>
          <p>Ristorante:<br />
          19:30/23:00 (lun/dom)</p>
          </div>*/}
          <p>19.00-24.00 lun/dom</p>
          </div>
          <div className="col-lg-4 sm-12 f_col">
          <p>SOCIAL NETWORKS</p>
          <div className="row icone_centering">
            <div className="col-3">
              <a href="https://www.facebook.com/LauritoCasaNatura/"><img src={Facebook} className="icone_social" alt="facebook" /></a></div>
            <div className="col-3">
              <a href="https://instagram.com/casanatura_laurito?igshid=9uo2aji657e1"><img src={Instagram} className="icone_social" alt="instagram" /></a></div>
            <div className="col-3">
              <a href="https://wa.me/393441310350"><img src={Wa} className="icone_social" alt="wa" /></a></div>
          </div>
          <br />
          <div className="row">
            <div className="col">
              <a href="https://www.iubenda.com/privacy-policy/14938385" 
                className="iubenda-nostyle no-brand iubenda-embed" 
                title="Privacy Policy "><span className="privacy">Privacy Policy</span></a>
                {(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document)}
            </div>
          </div>
    
          <br />
          <div className="row">
            <div className="col">
            <a href="https://www.iubenda.com/privacy-policy/14938385/cookie-policy" 
          className="iubenda-nostyle no-brand iubenda-embed" 
          title="Cookie Policy "><span className="privacy">Cookie Policy</span></a>
          {(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document)}

            </div>
          </div>
          </div>

          <div className="col-12" id="burocrazia" >
            coop. sociale onlus "al servizio della città" - via Garibaldi, 5  
            84078 Vallo della Lucania (SA) - P.iva e C.F. 02994340657 | <Link to="/login">Sei amministratore?</Link></div>
          </div>
        </div>
      </div>
    </>
  )
}
