import React, { Component } from 'react';
import "./CardEvento.css";

//import moment from "moment";

export default class CardEvento extends Component {

  constructor(props) {
    super(props);

    this.state = {
      listaEventi: []
    }
  }

  componentDidMount() {
    this.getEventi(); 
  
  }


  getEventi() {
    fetch("/get_eventi_tre", {
      method: 'POST',
    })
      .then(res => res.json())
      .then( result => {
        let listaEventi = result.data.map((item) => {
          const {id, titolo, descrizione, data, path_foto} = item;
          return {id, titolo, descrizione, data, path_foto}
        });
        this.setState({
          listaEventi
        });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    return (
      <div className="contenitore_eventi eventi">
          <div className="col titolo_eventi">EVENTI</div>
          <hr id="hr_sotto_albergo"/>

        <div className="row">
          {this.state.listaEventi.map((item) => {
            return(
              <div className="col-lg-4 sm-12 spacing_evento">
                
                <div className="evento_elem" key={item.id}>
                  <img src={"foto_eventi/"+item.path_foto} alt="foto_eventi" className="foto_eventi"/>
                  <div className="evento_title">
                    {item.titolo}
                  </div>
                  <div className="evento_descrizione">
                    {item.descrizione}
                  </div>
                  <div>
                  <hr />
                  <div className="evento_data">
                    {item.data}
                  </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
