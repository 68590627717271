import React, { Component } from 'react';
import piatto_1 from "../images/piatto_1.png";
//import piatto_2 from "../images/piatto_2.png";
//import piatto_3 from "../images/piatto_3.png";
import piatto_4 from "../images/piatto_4.png";
import "./Menu.css";

export default class Menu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      listaPiatti: [],
      tipoPiatti: []
    }
  }

  componentDidMount() {
    this.categoriaPiatto();
    this.getPiatti();
  }

  getPiatti() {
    fetch("/get_piatti_attivi", {
      method: 'POST',
    })
      .then(res => res.json())
      .then( result => {
        let listaPiatti = result.data.map((item) => {
          const {id, nome, descrizione, prezzo, attivo, idTipologia} = item;
          return {id, nome, descrizione, prezzo, attivo, idTipologia}
        });
        this.setState({
          listaPiatti
        });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  categoriaPiatto() {
    fetch("/categoria_piatto", {
      method: 'POST',
    })
      .then(res => res.json())
      .then( result => {
        let results = result;
        let tipoPiatti = results.data.map((item) => {
          const {id, descrizione} = item;
          return {id , descrizione}
        });
        this.setState({tipoPiatti});
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    return (
      <div className="cont" style={{marginTop: "10vh"}}>
        <div className="row">
          <div className="col riga_menu"></div>
          <div className="col titolo_menu">MENU</div>
          <div className="col riga_menu"></div>
        </div>
        <hr id="hr_sotto_menu"/>

        {this.state.tipoPiatti.map(tipo => {
          return(
            <>
            {(tipo.id % 2 === 0) ? 
            <div className="row">
              <div className="col-3"></div>
              <div className="col-5 descr_left_menu">
                <h3 className="tipo_piatti_menu">{tipo.descrizione}</h3>
                <div className="row">
                {this.state.listaPiatti.filter(item => item.idTipologia === tipo.id)
              .map( filteredPiatto =>{
                return(
                  <>
                    {(tipo.id % 2 === 0) ? 
                      <>
                          <div className="col-12">
                            <div className="row">
                              <div className="col">
                                <div className="nome_piatto_menu">
                                  {filteredPiatto.nome}
                                </div>
                              </div>
                              <div className="col">
                                <div className="prezzo_piatto_menu">
                                  {filteredPiatto.prezzo} &euro;
                                </div>
                              </div>
                            </div>
                            <hr className="separatore_piatto_menu"/>
                            <div className="descrizione_piatto_menu">
                              {filteredPiatto.descrizione}
                            </div>
                          </div>
                      </> 
                      :
                      ""
                    }
                  </>
                )
              })}
                </div> 
              </div>
              <div className="col-4 img_right_menu_cont">
                <img className="img_right_menu" alt="piatto_1" src={piatto_1} />
              </div>
              
            </div>
            :
            <div className="row">
              <div className="col-4 img_left_menu_cont">
                <img className="img_left_menu" alt="piatto_4" src={piatto_4} />
              </div>
              <div className="col-5 descr_left_menu">
                <h3 className="tipo_piatti_menu ">{tipo.descrizione}</h3>
                <div className="row">
                {this.state.listaPiatti.filter(item => item.idTipologia === tipo.id)
              .map( filteredPiatto =>{
                return(
                  <>
                    {(tipo.id % 2 !== 0) ? 
                      <>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-8">
                                <div className="nome_piatto_menu">
                                  {filteredPiatto.nome}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="prezzo_piatto_menu">
                                  {filteredPiatto.prezzo} &euro;
                                </div>
                              </div>
                            </div>
                            <hr className="separatore_piatto_menu"/>
                            <div className="descrizione_piatto_menu">
                              {filteredPiatto.descrizione}
                            </div>
                          </div>
                      </> 
                      :
                      ""
                    }
                  </>
                )
              })}
                </div> 
              </div>
              <div className="col-3"></div>
              
            </div>
            }
              
            </>
          )
        })}
        
      </div>
    )
  }
}

