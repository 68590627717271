import React, { Component } from 'react';
import {Link, withRouter/*, useRouteMatch as match*/} from "react-router-dom";
import $ from "jquery";
import Logo from "../images/logo.svg";
import "./Navbar.css";
import { Link as LinkLocal } from "react-scroll";

class Navbar extends Component {

  logOut(e){
    e.preventDefault();
    localStorage.removeItem("usertoken");
    this.props.history.push("/");
  }
 

  render() {


    /*$(window).bind('mousewheel', function(event) {
      if (event.originalEvent.wheelDelta > 0) {
        if($(window).scrollTop() === 0) {
          $(".bg-light").addClass("show_nav");
          $(".bg-light").removeClass("hide_nav");
        }
      }
      else {
        $(".bg-light").addClass("hide_nav");
        $(".bg-light").removeClass("show_nav");
      }
    });*/

    $(window).bind('mousewheel', function(event) {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
       
        function getPosition( el ) {
          var x = 0;
          var y = 0;
          while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
          x += el.offsetLeft - el.scrollLeft;
          y += el.offsetTop - el.scrollTop;
          el = el.offsetParent;
          }
          return { top: y, left: x };
        }
      
        var start_animation = getPosition(document.getElementById("info"));
        start_animation = start_animation.top -150;
        if(scrollTop > start_animation) {
          $('.navbar-nav .nav-item .nav-link .nav_el').removeClass('active');

        }

      var position = $(this).scrollTop();
  
      $('.inside_par').each(function() {
          var target = $(this).offset().top;
          var id = $(this).attr('id');
  
          if (position >= target) {
  
            var selettore = "#" + id + "_nav";
            $('.navbar-nav .nav-item .nav-link .nav_el').removeClass('active');
            $(selettore).addClass('active');
          }
      });


    });


    $(".nav-item").on("click",".nav-item",
      $(".navbar-collapse").removeClass("show")
    );

    /*const loginRegLink = (
      <>
        <li className="nav-item">
          <Link to="/login" className="nav-link">
            Login
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/register" className="nav-link">
            Register
          </Link>
        </li>
      </>
    );*/

    const userLink = (
      <>
        <li className="nav-item">
          <div className="dropdown">
            <span
            className="nav-link dropdown"
            id="dropdownMenuButton" 
            data-toggle="dropdown" aria-haspopup="true" 
            aria-expanded="false">
              Gestisci
            </span>
            <div className="dropdown-menu" style={{marginLeft:"50px"}} aria-labelledby="dropdownMenuButton">
              <Link to="/gest_menu">
                <div className="nav_el el_dropdown" >Gestisci menu</div>
              </Link>
              <br />
              <Link to="/gest_eventi">
                <div className="nav_el el_dropdown" >Gestisci eventi</div>
              </Link>
              <Link to="/gest_drink">
                <div className="nav_el el_dropdown" >Gestisci drink</div>
              </Link>
            </div>
            </div>
    
        </li>
        <li className="nav-item">
          <a href="/#" onClick={this.logOut.bind(this)} className="nav-link">
            <span className="nav_el">Logout</span>
          </a>
        </li>
      </>
    );

    $(document).ready(function(){
      $('.navbar-nav .nav-item .nav-link .nav_el').click(function(){
          $('.navbar-nav .nav-item .nav-link .nav_el').removeClass('active');
          $(this).addClass('active');
      })
    });

    return (
      <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-light">

          
 
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar1"
          aria-controls="navbar1"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <Link to="/" className="navbar-brand"><img className="logo-navbar" src={Logo} alt="" /></Link>
        <div className="collapse navbar-collapse justify-content-end" id="navbar1"> 
          <ul className="navbar-nav menuItems">
          {(this.props.location.pathname === "/gest_menu" 
          || this.props.location.pathname === "/menu"
          || this.props.location.pathname === "/drink"
          || this.props.location.pathname === "/gest_eventi"
          || this.props.location.pathname === "/albergo_spec"
          || this.props.location.pathname === "/gest_drink")
          ? <> 
            <li className="nav-item">
              <Link to="/" className="nav-link">
              <span className="nav_el">Home</span>
              </Link>
            </li>
            </>
          : <>
            <li className="nav-item">
              <LinkLocal to="ristorante" 
              className="nav-link " 
              spy={true}
              smooth={true}
              offset={-70}
              duration= {500}>
                <span className="nav_el " id="ristorante_nav">Ristorante</span>
              </LinkLocal>
            </li>
            <li className="nav-item">
              <LinkLocal to="bistrot" className="nav-link" 
               spy={true}
               smooth={true}
               offset={-70}
               duration= {500}>
              <span className="nav_el" id="bistrot_nav">Bistrot</span>
              </LinkLocal>
            </li>
            <li className="nav-item justify-content-end">
              <LinkLocal to="albergo" className="nav-link"
               spy={true}
               smooth={true}
               offset={-70}
               duration= {500} >
              <span className="nav_el" id="albergo_nav">Albergo</span>
              </LinkLocal>
            </li>
            <li className="nav-item justify-content-end">
              <LinkLocal to="footer" className="nav-link"
               spy={true}
               smooth={true}
               offset={-70}
               duration= {500} >
              <span className="nav_el">Contatti</span>
              </LinkLocal>
            </li>
            </>}
            
            {/*{this.props.location.pathname === "/gest_menu" ? 
            
    (localStorage.usertoken ? userLink : loginRegLink) : ""}*/}

            {localStorage.usertoken ? userLink : ""}

          </ul>
        </div>
    </nav>
</>
    )
  }
}

export default withRouter(Navbar);