import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import img_albergo1 from "../images/foto_stanza_albergo1_comp.jpg";
import img_albergo2 from "../images/foto_stanza_albergo2_comp.jpg";
import img_albergo3 from "../images/foto_stanza_albergo3_comp.jpg";


const CarouselPage = () => {
  return (
    <Carousel
    interval="2000"
    pause="false">
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={img_albergo1}
        alt="First slide"
      />
      <Carousel.Caption>
        
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={img_albergo2}
        alt="Third slide"
      />
  
      <Carousel.Caption>
        
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={img_albergo3}
        alt="Third slide"
      />
  
      <Carousel.Caption>
       
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
  );
}

export default CarouselPage;