import React, { Component } from 'react';
import "./DrinkList.css";

export default class DrinkList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      listaDrink: [],
      tipoDrink: []
    }
  }

  componentDidMount() {
    this.categoriaDrink();
    this.getDrink();
  }

  getDrink() {
    fetch("/get_drink_attivi", {
      method: 'POST',
    })
      .then(res => res.json())
      .then( result => {
        let listaDrink = result.data.map((item) => {
          const {id, nome, descrizione, prezzo, attivo, idTipologia} = item;
          return {id, nome, descrizione, prezzo, attivo, idTipologia}
        });
        this.setState({
          listaDrink
        });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  categoriaDrink() {
    fetch("/categoria_drink", {
      method: 'POST',
    })
      .then(res => res.json())
      .then( result => {
        let results = result;
        let tipoDrink = results.data.map((item) => {
          const {id, descrizione} = item;
          return {id , descrizione}
        });
        this.setState({tipoDrink});
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    return (
      <div className="cont" style={{marginTop: "10vh"}}>
        <div className="row">
          <div className="col"></div>
          <div className="col titolo_drink">APERITIVO</div>
          <div className="col"></div>
        </div>
        <hr id="hr_sotto_menu"/>

        {this.state.tipoDrink.map(tipo => {
          return(
            <>
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6 descr_drink">
                <h3 className="tipo_drink">{tipo.descrizione}</h3>
                <div className="row">
            {this.state.listaDrink.filter(item => item.idTipologia === tipo.id)
              .map( filteredDrink =>{
                return(
                  <>
                    <div className="col-12">
                      <div className="row">
                        <div className="col">
                          <div className="nome_drink">
                            {filteredDrink.nome}
                          </div>
                        </div>
                        <div className="col">
                          <div className="prezzo_drink">
                            {filteredDrink.prezzo} &euro;
                          </div>
                        </div>
                      </div>
                      <hr className="separatore_drink"/>
                      <div className="descrizione_drink">
                        {filteredDrink.descrizione}
                      </div>
                    </div>
                </>
                )
              })}
              </div>
              </div>
              </div>
            </>  
          )
        })}
      </div>
    )
  }
}

