import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Navbar from "./components/Navbar";
import Landing from "./components/Landing";
import Login from "./components/Login";
import Register from "./components/Register";
import Gest_menu from "./components/Gest_menu";
import Footer from "./components/Footer";
import Menu from "./components/Menu";
import DrinkList from "./components/DrinkList";  
import Albergo_spec from "./components/Albergo_spec";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import Gest_eventi from "./components/Gest_eventi";
import Gest_drink from "./components/Gest_drink";



function App() {

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar />
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Landing} />
        <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/menu`} component={Menu} />
        <Route exact path={`${process.env.PUBLIC_URL}/drink`} component={DrinkList} />  
        <Route exact path={`${process.env.PUBLIC_URL}/albergo_spec`} component={Albergo_spec} />
        <div>
          <Route exact path={`${process.env.PUBLIC_URL}/register`} component={Register} />
          <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
          <Route exact path={`${process.env.PUBLIC_URL}/gest_menu`} component={Gest_menu} />
          <Route exact path={`${process.env.PUBLIC_URL}/gest_eventi`} component={Gest_eventi} />
          <Route exact path={`${process.env.PUBLIC_URL}/gest_drink`} component={Gest_drink} />

        </div>
        </Switch>
      {/*<ScrollToTop />*/}
      <Footer />
      </div>
    </Router>
  );
}

export default App;
