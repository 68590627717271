import React, { Component } from 'react';
//import $ from "jquery";
import "./Albergo_spec.css";
import CarouselPage from "./CarouselPage";
import img_tv from "../images/tv.svg";
import img_shampoo from "../images/shampoo.svg";
import img_asciugamani from "../images/asciugamani.svg";
import img_calendario from "../images/calendario.svg";
import img_portafoglio from "../images/portafoglio.svg";
import img_online from "../images/online.svg";
import img_wifi from "../images/wifi.svg";
import img_panni from "../images/panni.svg";
//import img_calendar from "../images/calendar.svg";
import RichiediInfo from "./RichiediInfo";

export default class Albergo_spec extends Component {
  
  constructor(props) {
    super(props);

    this.state = {

    };
  }



  componentDidMount() {
 
   
  }


  render() {

    return (
      <div className="cont" style={{marginTop: "10vh"}}>
        <div className="row">
          <div className="col"></div>
          <div className="col titolo_albergo">ALBERGO</div>
          <div className="col"></div>
        </div>
        <hr id="hr_sotto_albergo"/>

        <CarouselPage />

        <div className="row">

          <div className="col-lg-5 sm-12 servizi_albergo">
            <div className="row">
                <h3 className="titolo_desc_albergo">LE CAMERE</h3>
            </div> 

            <div className="row riga_servizi">
              <div className="col-2">
                <img className="img_desc_riga_servizi" alt="tv" src={img_tv} />
              </div>
              <div className="col-10">
                <span className="titolo_servizi">TV</span> <br />
                <span className="desc_servizi">Casantura offre la possibliità della tv in stanza</span>
              </div>
            </div> 

            <div className="row riga_servizi">
              <div className="col-2">
                <img className="img_desc_riga_servizi" alt="shampoo" src={img_shampoo} />
              </div>
              <div className="col-10">
                <span className="titolo_servizi">KIT BAGNO</span> <br />
                <span className="desc_servizi">Nelle nostre stanze troverai un kit per l'igiene personale</span>
              </div>
            </div> 

            <div className="row riga_servizi">
              <div className="col-2">
                <img className="img_desc_riga_servizi" alt="asciugamani" src={img_asciugamani} />
              </div>
              <div className="col-10">
                <span className="titolo_servizi">BIANCHERIA</span> <br />
                <span className="desc_servizi">Le stanze sono dotate della biancheria essenziale</span>
              </div>
            </div>

            <div className="row riga_servizi">
              <div className="col-2">
                <img className="img_desc_riga_servizi" alt="wifi" src={img_wifi} />
              </div>
              <div className="col-10">
                <span className="titolo_servizi">WI-FI</span> <br />
                <span className="desc_servizi">L'intera struttura è dotata di connessione wirelles</span>
              </div>
            </div>

            <div className="row riga_servizi">
              <div className="col-2">
                <img className="img_desc_riga_servizi" alt="panni" src={img_panni} />
              </div>
              <div className="col-10">
                <span className="titolo_servizi">PULIZIE</span> <br />
                <span className="desc_servizi">La pulizia degli ambienti è la nostra priorità</span>
              </div>
            </div>
          </div>
          <div className="col-2"></div>


          <div className="col-lg-5 sm-12 descrizione_serviziEprenotazione">
          <div className="row riga_servizi">
            
            <div className="col-12" style={{textAlign:"center"}}>
              <button className="btn btn-lg btn_disponibilita"
              data-toggle="modal" data-target="#form_richiedi_info">Richiedi disponibilita</button>
            </div>

              <div className="col-12 desc_servizi long">
              Le stanze di casa natura sono semplici, accoglienti e confortevoli, 
              e vantano un affaccio panoramico unico.<br /> Le soluzioni che offriamo sono 
              flessibili, per un soggiorno realizzato su misura per voi.
              </div>
            </div>
            <hr style={{backgroundColor: "black"}}/>

            <div className="row info_prenotazioni">
              <div className="col-lg-4 sm-12 elem_info_prenotazioni">
                <div className="row">
                  <div className="col">
                    <img className="img_info_pronotazioni" alt="calendario" src={img_calendario} />
                  </div>
                </div>
                <div className="row">
                  <div className="col servizi_prenotazione">Prenotazione rapida</div>
                </div>
              </div>

              <div className="col-lg-4 sm-12 elem_info_prenotazioni">
                <div className="row">
                  <div className="col">
                    <img className="img_info_pronotazioni" alt="online" src={img_online} />
                  </div>
                </div>
                <div className="row">
                  <div className="col servizi_prenotazione">Cancellazine gratuita</div>
                </div>
              </div>

              <div className="col-lg-4 sm-12 elem_info_prenotazioni">
                <div className="row">
                  <div className="col">
                    <img className="img_info_pronotazioni" alt="prenotazioni" src={img_portafoglio} />
                  </div>
                </div>
                <div className="row">
                  <div className="col servizi_prenotazione">Pagamenti in contanti o con carta</div>
                </div>
              </div>

            </div>
            </div>
            <div className="row tabella_prezzi">
              <div className="col-12">
            <div className="info_agg_prezzi"> <h3 className="titolo_prezzi">PREZZI</h3></div>
              <table>
                <tr>
                  <th>Periodo</th>
                  <th>Prezzo</th>
                  {/*<th>Servizi aggiuntivi</th>*/}
                </tr>
                <tr>
                  <td><b>Bassa stagione:</b><br/>
                    Febbraio-Aprile <br/> Ottobre-Novembre</td>
                  <td> <b>25€</b> a persona (compresa colazione)<br />
                  <b>0€</b> bambini da 0-5 anni <br />
                  <b>15€</b> da 6-12 anni (compresa colazione)</td>
                  {/*<td>colazione: <b>25€</b> <br/>
                  colazione: <b>0€</b> <br />
                  colazione: <b>15€</b></td>*/}
                </tr>
                <tr>
                  <td><b>Alta stagione:</b><br/> Maggio-Settembre<br />Dicembre e Gennaio</td>
                  <td><b>30€</b> a persona (compresa colazione) <br />
                  Bambini come bassa stagione</td>
                  {/*<td>colazione: <b>30€</b> <br />
                  Come bassa stagione</td>*/}
                </tr>
              </table><br />
              <div className="info_agg_prezzi">
              * Per eventuali informazioni da concordare via e-mail o 
              direttamente con la direzione</div>
              <div className="info_agg_prezzi">
              ** Supplemento animali 5€ al giorno</div>
            </div>  
            </div>

        </div>
        <RichiediInfo />
      </div>  
    )
  }
}
