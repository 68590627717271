import React, { Component } from 'react';
import "./Landing.css";
import Im_landing from "../images/landing-page-comp-crop.png";
import Logo from "../images/logo.svg";
import Info from "./Info";
import $ from "jquery";
import { Link as LinkLocal } from "react-scroll";
import icona_frecce from "../images/icona_frecce.svg";



export default class Landing extends Component {

  componentDidMount() {
   
    var pathname = window.location.pathname; 
    function getPosition( el ) {
      var x = 0;
      var y = 0;
      while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
      }
      return { top: y, left: x };
    }
   
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    var start_animation = getPosition(document.getElementById("info"));

    if(pathname === "/" && scrollTop < start_animation.top)
      $(".bg-light").addClass("disopacizza_navbar");


  }

  rimuoviActive() {
    $('.navbar-nav .nav-item .nav-link .nav_el').removeClass('active');
    $(".bg-light").addClass("disopacizza_navbar");
  }

  render() {
    /*var entrato = 0;
      $(window).bind('mousewheel', function(event) {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if(entrato === 0){
        function getPosition( el ) {
          var x = 0;
          var y = 0;
          while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
          x += el.offsetLeft - el.scrollLeft;
          y += el.offsetTop - el.scrollTop;
          el = el.offsetParent;
          }
          return { top: y, left: x };
        }
        
        var start_animation = getPosition(document.getElementById("info"));
        var start_animation = start_animation.top -150;
        //console.log(start_animation.top);
        //console.log(scrollTop);


        if(scrollTop > start_animation) {         
          $(".bg-light").removeClass("disopacizza_navbar");
          $(".bg-light").addClass("opacizza_navbar");
          $(".logo").css("opacity", 0);
          $(".logo-navbar").css("opacity" , 1);
        }
        else if(start_animation> scrollTop) {
          $(".bg-light").removeClass("opacizza_navbar");
          $(".bg-light").addClass("disopacizza_navbar");
          $(".logo").css("opacity", 1);
          $(".logo-navbar").css("opacity", 0);
        }
      }

      if (event.originalEvent.wheelDelta > 0) {
        if($(window).scrollTop() === 0) {
          $(".logo").addClass("moveCenter");
          $(".logo").removeClass("moveUp");
          $(".arrow").show("slow");
          $(".slogan").fadeIn("slow");
          entrato = 0;
        }
      }
      else {
        $(".bg-light").css("opacity", 1);
        $(".bg-light").css("background-color", "transparent");
        $(".logo").addClass("moveUp");
        $(".logo").removeClass("moveCenter");
        $(".arrow").hide("slow");
        $(".slogan").fadeOut("slow");
      }
    });
*/

$(window).bind('mousewheel', function(event) {
  function getPosition( el ) {
    var x = 0;
    var y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
    x += el.offsetLeft - el.scrollLeft;
    y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
    }
    return { top: y, left: x };
  }
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  var start_animation = getPosition(document.getElementById("info"));
  start_animation = start_animation.top -150;

  if(scrollTop > start_animation) {         
    $(".bg-light").removeClass("disopacizza_navbar");
    $(".bg-light").addClass("opacizza_navbar");

  }
  else if(start_animation> scrollTop) {
    $(".bg-light").removeClass("opacizza_navbar");
    $(".bg-light").addClass("disopacizza_navbar");

  }
});
    return (
      <>
      <div id="inizio"></div>
      <div className="container-land" id="container-land">
      <div className="landing-container" id="img-landing" name="img-landing">

       <img className="img-landing" alt="landing" src={Im_landing} />
  
     
        <img className="logo" id="logo" src={Logo} width="50%" height="50%" alt="" />
        <div className="slogan">
          TRADIZIONE E INNOVAZIONE <br /> CONVERGONO IN UNA REALTA' UNICA <br /> 
          IMMERSA NEL PARCO NAZIONALE DEL CILENTO
        </div>
        <div className="arrow bounce"></div>
      </div>
      </div>
      <LinkLocal to="inizio"
      spy={true}
      smooth={true}
      offset={-70}
      duration= {500}>
      <div id="button_tornaSu" onClick={this.rimuoviActive}><img style={{width: "50%", marginTop: "15px"}} alt="icona_frecce" src={icona_frecce}/></div>
      </LinkLocal>
      <Info className="contenitore_info"/>
      </>
    )
  }
}
