import React, { Component } from 'react';
import jwt_decode from "jwt-decode";
import "./Gest_eventi.css";
import DatePicker /*, {registerLocale, setDefaultLocale }*/ from 'react-datepicker';
import moment from "moment";
import Delete from "../images/close.svg";
import Modifica from "../images/edit.svg";
import AOS from 'aos';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import $ from "jquery";

export default class Gest_eventi extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      id: "",
      titolo: "",
      data:"",
      descrizione:"",
      selectedFile: null,

      id_mod:"",
      titolo_mod: "",
      data_mod:"",
      descrizione_mod:"",
      selectedFile_mod: null,

      blocking: false,
      listaEventi:[]
    }

    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.onChangeHandler_file = this.onChangeHandler_file.bind(this);
    this.onChangeHandler_file_mod = this.onChangeHandler_file_mod.bind(this);
    this.submitEvento = this.submitEvento.bind(this);
    this.gestisciModifica = this.gestisciModifica.bind(this);
    this.submitModifica = this.submitModifica.bind(this);
    this.handleChange_data_mod = this.handleChange_data_mod.bind(this);
    this.eliminaElemento = this.eliminaElemento.bind(this);
    this.blockSchermo = this.blockSchermo.bind(this);
    this.sbloccoSchermo = this.sbloccoSchermo.bind(this);

  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    this.get_eventi();
    this.setState({
      username: decoded.username
    });
    $(window).on('load', function() {
      AOS.refresh();
      });
      AOS.refresh();
  }

  blockSchermo() {
    this.setState({
      blocking: true
    });
  }

  sbloccoSchermo() {
    this.setState({
      blocking: false
    });
  }

  logOut(e){
    //e.preventDefault();
    localStorage.removeItem("usertoken");
    this.props.history.push("/");
  }

  submitEvento() {
    this.blockSchermo();
    //var objSave = {};
    const formData = new FormData();

    /*formData = {
      titolo: this.state.titolo,
      data: this.state.data,
      descrizione: this.state.descrizione
    }*/

    let data = moment(this.state.data).format("DD/MM/yyyy");
   
    formData.append('selectedFile', this.state.selectedFile);
    formData.append('titolo', this.state.titolo);
    formData.append('data', data);
    formData.append('descrizione', this.state.descrizione);
    formData.append('token', localStorage.usertoken);
    formData.set("data", data);

    fetch('/aggiungi_evento', {  
      method: 'POST',
      headers: {
          //'Accept': 'application/json',
          "Contetnt-Type":"multipart/form-data" 
      },
      body: formData
    })
    .then(res => res.json())
    .then(result => {
      if(result.error){
        alert("Sessione scaduta, rieseguire il login");
        this.logOut();
      } else {
        this.setState({
          id: "",
          titolo: "",
          data:"",
          descrizione:"",
          selectedFile: null,
        });
        this.get_eventi();
      }
    })
  }


  get_eventi() {
    this.blockSchermo();
    fetch("/get_eventi", {
      method: 'POST',
    })
      .then(res => res.json())
      .then( result => {
        let listaEventi = result.data.map((item) => {
          const {id, titolo, descrizione, data, path_foto, attivo} = item;
          return {id, titolo, descrizione, data, path_foto, attivo}
        });
        this.setState({
          listaEventi
        }, () => this.sbloccoSchermo());
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  inputChangeHandler(evt) {
    if(evt.target.type === "checkbox"){
      this.blockSchermo();
      fetch('/disattiva_attiva_evento', {  
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({id: evt.target.id, token:localStorage.usertoken})  
    })
    .then(res => res.json())
    .then(result => {
      if(result.error){
        alert("Sessione scaduta, rieseguire il login");
        this.logOut();
      } else {

        this.get_eventi();
      }
    })
    };
    this.setState({ 
      [evt.target.name]: evt.target.value 
    });
   }

  handleChange_data=date=>{
    //var data_format = (moment(date).format("DD/MM/yyyy"));
    this.setState({
      data: date
    });
  };

  handleChange_data_mod(date){
    var data = (moment(date).format("DD/MM/yyyy"));
    this.setState({
      data_mod: data
    });
  }


  onChangeHandler_file=event=>{
    this.setState({
      selectedFile: event.target.files[0]
    })

    /*formData = {
      titolo: this.state.titolo,
      data: this.state.data,
      descrizione: this.state.descrizione
    }*/
  }

  onChangeHandler_file_mod = event => {
    this.setState({
      selectedFile_mod: event.target.files[0]
    })
  }

  gestisciModifica(props){
 
    this.setState({
      id_mod:props.id,
      titolo_mod: props.titolo,
      data_mod:props.data,
      descrizione_mod:props.descrizione,
      selectedFile_mod: props.selectedFile
    });
  }

  submitModifica() {
     //const formData = new FormData();

    /*formData = {
      titolo: this.state.titolo,
      data: this.state.data,
      descrizione: this.state.descrizione
    }*/


   //let data_mod = moment(this.state.data_mod).format("DD/MM/yyyy");
   
    var objSave = {};

    objSave = {
      id: this.state.id_mod,
      titolo: this.state.titolo_mod,
      descrizione: this.state.descrizione_mod
    }
    objSave.data = this.state.data_mod;

    fetch('/modifica_evento', {  
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify({objSave, token:localStorage.usertoken}) 
    }).then(res => res.json())
    .then(result => {
      if(result.error){
        alert("Sessione scaduta, rieseguire il login");
        this.logOut();
      } else {
        this.get_eventi();
      }
    })
  }

  eliminaElemento(evt) {  
    fetch('/elimina_evento', {  
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
        body: JSON.stringify({id: evt.target.id, token:localStorage.usertoken})  
      }).then(res => res.json())
      .then(result => {
        if(result.error){
          alert("Sessione scaduta, rieseguire il login");
          this.logOut();
        } else {
          this.get_eventi();
        }
      })
  }

  render() {

    return (
      <div className="container font">
        <BlockUi tag="div" blocking={this.state.blocking}>
        <div id="evento_title">Aggiungi evento</div>
        <div className="info_necessarie">
          <div className="row">
            <div className="col-6">
              <label>Titolo</label>
              <input className="form-control" type="text" placeholder="Titolo"
              name="titolo"
              value={this.state.titolo}
              onChange={this.inputChangeHandler}
              />
            </div>
              <div className="col-6">
              <label>Data</label>
              <DatePicker 
                selected={this.state.data}
                onChange={this.handleChange_data}
                className="date_picker_eventi"
                placeholder="GG/MM/AAAA"
                dateFormat="dd/MM/yyyy"
                locale="it"
                value={this.state.data}
              />
            </div> 
          </div>  
          <div className="row">
              <div className="col-lg-6 sm-12 descrizione_evento">
                <label>Descrizione</label>
                <textarea className="form-control" 
                  rows="3"
                  placeholder="Descrizione"
                  name="descrizione"
                  value={this.state.descrizione}
                  onChange={this.inputChangeHandler}
                />  
              </div>

              <div className="col-lg-6 sm-12 descrizione_evento">
                <label>Aggiungi foto</label>
                <input className="form-control" 
                  placeholder="Clicca per selezionare"
                  name="selectedFile"
                  type="file"
                  onChange={this.onChangeHandler_file}
                />  
              </div>
            </div>
            <div className="row">
              <div className="col-12 button_salva_evento">
                <button className="btn btn-primary"
                onClick={this.submitEvento}>Salva</button>
              </div>
            </div> 
        </div>

        <div className="container_eventi">
        <h3 className="tipo_piatti">Eventi</h3>
          <div className="container titoli">
          <div className="row divisore">
            <div className="col">Titolo</div>
            <div className="col">Descrizione</div>
            <div className="col">Data</div>
            <div className="col azioni">Azioni</div>
          </div>
        {this.state.listaEventi.map(item => {
          return(
            <div className="row divisore" key={item.id}>
              <div className="col">{item.titolo}</div>
              <div className="col">{item.descrizione}</div>
              <div className="col">{item.data}</div>
              <div className="col">
                <div className="row">
                  <div className="col">
                    <img className="edit" 
                    src={Modifica}
                    alt="modifica" 
                    onClick={() => this.gestisciModifica(item)}
                    data-toggle="modal" 
                    data-target="#modifica"/>
                  </div>
                  <div className="col">
                    <img className="delete" 
                    alt="delete"
                    src={Delete}
                    id={item.id}
                    onClick={this.eliminaElemento}/>
                  </div>
                  <div className="col">
                  <label className="switch">
                    <input type="checkbox" 
                    className="checkbox" 
                    key={item.id}
                    id={item.id}
                    checked={item.attivo === 1 ? true : false}
                    onChange={this.inputChangeHandler}/>
                    <span className="slider round"></span>
                  </label>
                  </div>
                </div>
              </div>
            </div>
        )
        })}

        <div className="modal fade" id="modifica" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Modifica</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">

              <div className="row">
                <div className="col-4">
                  <label>Titolo</label>
                  <input className="form-control" type="text" placeholder="Titolo"
                  name="titolo_mod"
                  value={this.state.titolo_mod}
                  onChange={this.inputChangeHandler}/>
                </div>
                <div className="col-4">
                  <label>Data</label>
                  <DatePicker 
                    //selected={this.state.data_mod}
                    onChange={this.handleChange_data_mod}
                    className="date_picker_eventi"
                    placeholder="GG/MM/AAAA"
                    dateFormat="DD/MM/yyyy"
                    locale="it"
                    value={(this.state.data_mod) ? this.state.data_mod : ""}
                  />
                </div>
                
              </div>
              <br />
              <div className="row">
              <div className="col-12">
                  <label>Descrizione</label>
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                  name="descrizione_mod"
                  value={this.state.descrizione_mod}
                  onChange={this.inputChangeHandler}
                  />
              </div>
              </div>
              
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Annulla</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal"
                onClick={this.submitModifica}>Salva</button>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
        </BlockUi>
      </div>
    )
  }
}