import React, { Component } from 'react';
import jwt_decode from "jwt-decode";
import "./Gest_drink.css";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import jsPDF from "jspdf";
import pdfIcon from "../images/pdf.svg";
import Delete from "../images/close.svg";
import Modifica from "../images/edit.svg";
import topBottom from "../images/topBottom.svg";

export default class Gest_drink extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      tipoDrink: [],
      idTipoDrink: "1",
      listaDrink: [],
      nome: "",
      prezzo: "",
      descrizione: "",

      nome_mod:"",
      prezzo_mod:"",
      descrizione_mod: "",
      idTipoDrink_mod: "",
      id_mod: "",
      blocking:false,
      errore_show: false, 
      op_effettuata: false
    }

    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.gestisciModifica = this.gestisciModifica.bind(this);
    this.eliminaElemento = this.eliminaElemento.bind(this);
    this.blockSchermo = this.blockSchermo.bind(this);
    this.sbloccoSchermo = this.sbloccoSchermo.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.submitModifica = this.submitModifica.bind(this);
    this.exportDrinkList = this.exportDrinkList.bind(this);
    this.logOut = this.logOut.bind(this);

  }
  

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    this.setState({
      username: decoded.username
    });
    this.categoriaDrink();
    this.getDrink();
    this.getPiattiAttivi();
  }


  disattivaNotifica() {  
    this.setState({inviato: false}); 
  }

  blockSchermo() {
    this.setState({
      blocking: true
    });
  }

  sbloccoSchermo() {
    this.setState({
      blocking: false
    });
  }

  logOut(e){
    //e.preventDefault();
    localStorage.removeItem("usertoken");
    this.props.history.push("/");
  }

  exportDrinkList(){
    var doc = new jsPDF();
  
    doc.setTextColor("#BA1852");
    doc.setFontSize(30);
    doc.setFontType("bold");
    doc.text('DRINK LIST', 75, 20);
    var posY = 20;
    var pageHeight= 240;
    

    this.state.tipoDrink.map(tipo => {
      doc.setFontSize(18);
      doc.setTextColor("#BA1852");
      posY += 20;
      doc.setFontType("bold");
      doc.text(tipo.descrizione, 30, posY);
      doc.setTextColor("0","0","0");
      this.state.listaDrinkAttivi.filter(item => item.idTipologia === tipo.id)
        .map( filteredDrink =>{
          doc.setFontType("normal");
          if (posY>=pageHeight)
          {
            doc.addPage();
            posY = 10;
          }

          doc.setFontSize(12);
          posY += 10;
          doc.setTextColor("#BA1852");
          doc.text(filteredDrink.nome, 30, posY);
          doc.text(filteredDrink.prezzo +' €', 180, posY, {align:'right'});
          doc.setTextColor("0","0","0");
          posY += 2;
          doc.setDrawColor("0","0","0");
          doc.line(30, posY, 180, posY);
          posY += 5;
          doc.text(filteredDrink.descrizione, 30, posY, {maxWidth: 150});
          console.log(String(filteredDrink.descrizione.length));
          if(filteredDrink.descrizione.length > 75){
            posY += 5;  
          }else if(filteredDrink.descrizione.length > 150){
            posY += 10;
          };
          posY += 5;
        })
    })

    doc.save('DrinkList.pdf');
  }

  getPiattiAttivi() {
    fetch("/get_drink_attivi", {
      method: 'POST',
    })
      .then(res => res.json())
      .then( result => {
        let listaDrinkAttivi = result.data.map((item) => {
          const {id, nome, descrizione, prezzo, attivo, idTipologia} = item;
          return {id, nome, descrizione, prezzo, attivo, idTipologia}
        });
        this.setState({
          listaDrinkAttivi
        });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  inputChangeHandler(evt) {
    if(evt.target.type === "checkbox"){
      this.blockSchermo(); 
      fetch('/disattiva_attiva_drink', {  
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({id: evt.target.id,token:localStorage.usertoken})  
    })
    .then(res => res.json())
    .then(result => {
      if(result.error){
        alert("Sessione scaduta, rieseguire il login");
        this.logOut();
      } else {

        this.getDrink();
      }
    })
    };
    this.setState({ 
      [evt.target.name]: evt.target.value 
    });
  }

  getDrink() {
    this.blockSchermo(); 
    fetch("/get_drink", {
      method: 'POST',
    })
      .then(res => res.json())
      .then( result => {
        let listaDrink = result.data.map((item) => {
          const {id, nome, descrizione, prezzo, attivo, idTipologia, posizione} = item;
          return {id, nome, descrizione, prezzo, attivo, idTipologia, posizione}
        });
        this.setState({
          listaDrink
        }, () =>  this.sbloccoSchermo());
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  categoriaDrink() {
    fetch("/categoria_drink", {
      method: 'POST',
    })
      .then(res => res.json())
      .then( result => {
        let results = result;
        let tipoDrink = results.data.map((item) => {
          const {id, descrizione} = item;
          return {id , descrizione}
        });
        this.setState({tipoDrink});
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  gestisciModifica(props){
    this.setState({
      nome_mod: props.nome,
      idTipoDrink_mod: props.idTipologia,
      prezzo_mod: props.prezzo,
      descrizione_mod: props.descrizione,
      id_mod: props.id
    });
  }

  eliminaElemento(evt) {  
    fetch('/elimina_drink', {  
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
        body: JSON.stringify({id: evt.target.id, token:localStorage.usertoken})  
      }).then(res => res.json())
      .then(result => {
        if(result.error){
          alert("Sessione scaduta, rieseguire il login");
          this.logOut();
        } else {
          this.getDrink();
        }
    })
  }


  moveElem(pos, elem) {
    this.blockSchermo(); 
    var tipo = elem.idTipologia;
    var id = elem.id;
    var posizione = elem.posizione;
    console.log(posizione);
    fetch('/muovi_drink', {  
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({movimento: pos, tipo, id, posizione, token:localStorage.usertoken})  
    }).then(res => res.json())
    .then((result) => {
      if(result.error){
      alert("Sessione scaduta, rieseguire il login");
      this.logOut();
    } else {
      this.getDrink();
    }})
    this.sbloccoSchermo();
  }

  submitModifica() {
    var objSave = {};

    objSave = {
      id: this.state.id_mod,
      tipo: this.state.idTipoDrink_mod,
      nome: this.state.nome_mod,
      prezzo: this.state.prezzo_mod,
      descrizione: this.state.descrizione_mod
    }
    if(objSave.tipo !== "" && objSave.nome !== "" && objSave.prezzo !== "") {
        fetch('/modifica_drink', {  
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({objSave, token:localStorage.usertoken})  
        })
        .then(res => res.json())
        .then(result => {
          if(result.error){
            alert("Sessione scaduta, rieseguire il login");
            this.logOut();
          } else {
            this.getDrink();
          }
        })
      }
  }

  submitHandler(){
    var objSave = {};

    objSave = {
      tipo: this.state.idTipoDrink,
      nome: this.state.nome,
      prezzo: this.state.prezzo,
      descrizione: this.state.descrizione
    }
    if(objSave.tipo !== "" && objSave.nome !== "" && objSave.prezzo !== "") {
        fetch('/aggiungi_drink', {  
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({objSave, token:localStorage.usertoken})  
        })
        .then((response) => response.json())
        .then(res => {
          if(res.error){
            alert("Sessione scaduta, rieseguire il login");
            this.logOut();
          } else {
            let listaDrink = res.data.map((item) => {
              const {id, nome, descrizione, prezzo, attivo, idTipologia, posizione} = item;
              return {id, nome, descrizione, prezzo, attivo, idTipologia, posizione}
            });
            this.setState({
              listaDrink,
              nome: "",
              prezzo: "",
              descrizione: ""
            });
          }
        })
    .catch(error => console.log("Si è verificato un errore!"));
    }
  }
  

  render() {
    return (
      <div className="font container_gest_drink">
        <BlockUi tag="div" blocking={this.state.blocking}>
        <p id="drink_title">Aggiungi drink</p>

        <div id="aggiungi_drink">
          <div className="row">
            <div className="col-4">
              <label>Tipologia</label>
              <select className="form-control" onChange={this.inputChangeHandler} 
              value={this.state.tipoDrink.value}
              name="idTipoDrink">
                {this.state.tipoDrink.map((item) =>{
                  return(
                  <option key={item.id}
                  value={item.id}>
                    {item.descrizione}
                  </option>
                  )
                })}
              </select>
            </div>
            <div className="col-4">
              <label>Nome</label>
              <input className="form-control" type="text" placeholder="Nome"
              name="nome"
              value={this.state.nome}
              onChange={this.inputChangeHandler}/>
            </div>
            <div className="col-4">
              <label>Prezzo</label>
              <input className="form-control" type="text" placeholder="Prezzo"
              name="prezzo"
              value={this.state.prezzo}
              onChange={this.inputChangeHandler}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <label>Descrizione</label>
              <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
              name="descrizione"
              value={this.state.descrizione}
              onChange={this.inputChangeHandler}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <button 
                className="btn btn-outline-danger btn-lg" 
                onClick={this.submitHandler} >
                <div className="descr_in_btn">Aggiungi drink</div>
              </button>
            </div>
            <div className="col-2" style={{textAlign: "center"}}>
              <img className="pdfIcon" src={pdfIcon} onClick={this.exportDrinkList}/>
            </div>
          </div>
        </div>
        
        {/*ANTIPASTI*/}
        <div className="container_drink">
        {this.state.tipoDrink.map(tipo => {
          return(
            <>
              <h3 className="tipo_drink">{tipo.descrizione}</h3>
              <div className="titoli">
              <div className="row divisore">
                <div className="col">Nome</div>
                <div className="col">Prezzo</div>
                <div className="col">Descrizione</div>
                <div className="col azioni">Azioni</div>
              </div>
              </div>
              {this.state.listaDrink.filter(item => item.idTipologia === tipo.id)
                .map( filteredDrink =>{
                  return(
                    <div className="row divisore" key={filteredDrink.id}>
                      <div className="col">{filteredDrink.nome}</div>
                      <div className="col"><>&euro; </>{filteredDrink.prezzo}</div>
                      <div className="col">{filteredDrink.descrizione}</div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <img className="edit"
                              alt="edit" 
                              src={Modifica} 
                              onClick={() => this.gestisciModifica(filteredDrink)}
                              data-toggle="modal" 
                              data-target="#modifica"/>
                          </div>
                          <div className="col">
                            <img className="delete" 
                            alt="delete"
                            src={Delete}
                            id={filteredDrink.id}
                            onClick={this.eliminaElemento}/>
                          </div>
                          <div className="col">
                            <label className="switch">
                            <input type="checkbox" 
                            className="checkbox" 
                            key={filteredDrink.id}
                            id={filteredDrink.id}
                            checked={filteredDrink.attivo === 1 ? true : false}
                            onChange={this.inputChangeHandler}/>
                            <span className="slider round"></span>
                          </label>
                          </div>
                          <div className="col arrows">
                            <div className="row"><div className="col">
                              <img className="topBottom arr_top" 
                                src={topBottom} 
                                onClick={() => this.moveElem(1, filteredDrink)}
                              />
                            </div></div>
                            
                            <div className="row"><div className="col">
                              <img className="topBottom arr_bottom" 
                                src={topBottom} 
                                onClick={() => this.moveElem(2, filteredDrink)}
                              />
                            </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  )
                })}
            </>
          )
        })}
        </div>


        <div className="modal fade" id="modifica" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Modifica</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">

              <div className="row">
                <div className="col-4">
                  <label>Tipologia</label>
                  <select className="form-control" onChange={this.inputChangeHandler} 
                  value={this.state.idTipoDrink_mod}
                  name="idTipoDrink_mod">
                    {this.state.tipoDrink.map((item) =>{
                      return(
                      <option key={item.id}
                      value={item.id}>
                        {item.descrizione}
                      </option>
                      )
                    })}
                  </select>
                </div>
                <div className="col-4">
                  <label>Nome</label>
                  <input className="form-control" type="text" placeholder="Nome"
                  name="nome_mod"
                  value={this.state.nome_mod}
                  onChange={this.inputChangeHandler}/>
                </div>
                <div className="col-4">
                  <label>Prezzo</label>
                  <input className="form-control" type="text" placeholder="Prezzo"
                  name="prezzo_mod"
                  value={this.state.prezzo_mod}
                  onChange={this.inputChangeHandler}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12">
                  <label>Descrizione</label>
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                  name="descrizione_mod"
                  value={this.state.descrizione_mod}
                  onChange={this.inputChangeHandler}
                  />
                </div>
                </div>
            
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Annulla</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal"
                onClick={this.submitModifica}>Salva</button>
              </div>
            </div>
          </div>
        </div>

        </BlockUi>
      </div>
    )
  }
}
